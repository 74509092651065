import React from 'react';

function Privacy() {
  return (
    <div className="privacy-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Politique de Confidentialité</h1>
      <p>Cette politique de confidentialité décrit comment GEROBA (ci-après « l'Application ») collecte, utilise et protège vos informations personnelles.</p>
      <ol>
        <li>
            <h3>Informations Collectées</h3>
            <p>L'Application collecte les informations suivantes :</p>
            <ul>
                <li><strong>Informations d'identification</strong> : Nom, adresse e-mail et mot de passe de l'utilisateur.</li>
                <li><strong>Données de localisation</strong> : L'Application utilise les données de localisation uniquement pour les fonctionnalités liées à la géolocalisation au sein de l'application.</li>
                <li><strong>Données Bluetooth</strong> : L'Application utilise le Bluetooth uniquement pour les fonctionnalités liées au Bluetooth au sein de l'application.</li>
                <li><strong>Accès à la caméra</strong> : dans le cadre exclusif des fonctionnalités de l'application, comme par exemple, scanner un QR code, ou prendre une photo lors de l'utilisation de l'application.</li>
                <li><strong>Stockage de l'appareil</strong> :  L'Application peut accéder au stockage de l'appareil pour sauvegarder des données liées à l'utilisation de l'application, comme des photos, des fichiers de configuration, ou des données de cache.</li>
             </ul>
        </li>
        <li>
            <h3>Utilisation des Informations</h3>
            <p>Les informations collectées sont utilisées aux fins suivantes :</p>
            <ul>
                <li>Fournir et améliorer les fonctionnalités de l'Application.</li>
                <li>Gérer les comptes utilisateurs et permettre la connexion.</li>
                <li>Assurer le bon fonctionnement des fonctionnalités Bluetooth, de géolocalisation, de la caméra et de stockage.</li>
                <li>Réaliser les actions demandées par l'utilisateur, comme par exemple, sauvegarder une photo, lors de son utilisation de l'application.</li>
          </ul>
        </li>
        <li>
            <h3>Partage des Informations</h3>
           <ul>
                <li>L'Application ne partage en aucun cas vos informations personnelles avec des tiers.</li>
                <li>Vos données personnelles restent dans l'application, et ne seront en aucun cas transmise à des tiers.</li>
          </ul>
        </li>
        <li>
            <h3>Sécurité des Informations</h3>
           <p>Conformément à la législation en vigueur, vous disposez des droits suivants :</p>         
        </li>
        <li>
            <h3>Vos Droits</h3>
            <p>Conformément à la législation en vigueur, vous disposez des droits suivants :</p>
            <ul>
                <li>Droit d'accès : Vous pouvez demander l'accès à vos informations personnelles.</li>
                <li>Droit de rectification : Vous pouvez demander la correction de vos informations personnelles3 inexactes ou incomplètes.</li>
                <li>Droit de suppression : Vous pouvez demander la suppression de vos informations personnelles.</li>
                <li>Droit d'opposition : Vous pouvez vous opposer au traitement de vos informations4 personnelles pour des motifs légitimes.</li>
                <li>Vous pouvez modifier vos données personnelles directement depuis l'application.</li>
          </ul>
          <p>Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : contact@gerobamaster.fr.</p>
        </li>
        <li>
            <h3>Modifications de la Politique de Confidentialité</h3>
           <p>Nous nous réservons le droit de modifier cette politique de5 confidentialité à tout moment. Les modifications seront publiées6 dans l'Application et prendront effet immédiatement.</p>         
        </li>
        <li>
            <h3>Contact</h3>
            <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à :</p>
            <ul>
                <li><p><a href="mailto:contact@gerobamaster.fr">contact@gerobamaster.fr</a></p></li>
          </ul>
          <p>Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : contact@gerobamaster.fr.</p>
        </li>
      </ol>
      <h3>Informations Importantes sur la Caméra et le Stockage</h3>
            <ul>
                <li>L'accès à la caméra et au stockage de l'appareil est uniquement utilisé pour les fonctionnalités spécifiques de l'application et avec le consentement de l'utilisateur.</li>
                <li>L'application n'accède pas à la caméra ou au stockage de l'appareil en arrière-plan sans le consentement explicite de l'utilisateur.</li>
                <li>Dans le cas de l'utilisation de la camera, aucune photo ou vidéo, ne sera transmise à l'exterieur de l'appareil de l'utilisateur sans son consentement.</li>
          </ul>
    </div>
  );
}

export default Privacy;