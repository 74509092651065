import React from 'react';
import ReactDOM from 'react-dom/client';
import Privacy from './components/Privacy';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} /> {/* App comme page d'accueil */}
          <Route path="/privacy" element={<Privacy />} /> {/* Route vers Privacy */}
          <Route path="*" element={<div>Page non trouvée</div>} /> {/* 404 */}
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


